import React from "react";
import { Link, graphql } from "gatsby";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import Seo from "gatsby-plugin-wpgraphql-seo";

import "../css/@wordpress/block-library/build-style/style.css";
import "../css/@wordpress/block-library/build-style/theme.css";
import "../assets/styles/theme.scss";

import IconBack from "../assets/images/icon-back-to.svg";

import Header from "../components/header.js";
import Footer from "../components/footer.js";

const SingleBlog = ({ data: { previous, next, post, site } }) => {
  // const featuredImage = {
  //   data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
  //   alt: post.featuredImage?.node?.alt || ``,
  // };
  const postFeaturedImage = post?.featuredImage?.node?.mediaItemUrl || false;

  const styleTheme1 = `${site?.siteMetadata?.cmsURL}/wp-content/themes/hello-elementor/style.min.css?ver=2.5.0`;
  const styleTheme2 = `${site?.siteMetadata?.cmsURL}/wp-content/themes/hello-elementor/theme.min.css?ver=2.5.0`;
  const styleElementorFrontEnd = `${site?.siteMetadata?.cmsURL}/wp-content/plugins/elementor/assets/css/frontend-lite.min.css?ver=3.6.0`;
  const styleElementorConfig = `${site?.siteMetadata?.cmsURL}/wp-content/uploads/elementor/css/post-21.css}`;
  const styleElementorIcons = `${site?.siteMetadata?.cmsURL}/wp-content/plugins/elementor/assets/lib/eicons/css/elementor-icons.min.css?ver=5.15.0`;
  const styleElementorFontAwesome = `${site?.siteMetadata?.cmsURL}/wp-content/plugins/elementor/assets/lib/font-awesome/css/fontawesome.min.css?ver=5.15.3`;
  const styleElementorFASolid = `${site?.siteMetadata?.cmsURL}/wp-content/plugins/elementor/assets/lib/font-awesome/css/solid.min.css?ver=5.15.3`;
  const styleElementorBrands = `${site?.siteMetadata?.cmsURL}/wp-content/plugins/elementor/assets/lib/font-awesome/css/brands.min.css?ver=5.15.3`;
  const styleElementorPro = `${site?.siteMetadata?.cmsURL}/wp-content/plugins/elementor-pro/assets/css/frontend-lite.min.css?ver=3.6.4`;
  const styleElementorGlobal = `${site?.siteMetadata?.cmsURL}/wp-content/uploads/elementor/css/global.css}`;

  return (
    <>
      <Seo post={post} />
      <div id={`page-${post.databaseId}`} className="page-container">
        <Seo title={post.title} description={post.title} />

        <Helmet
          bodyAttributes={{
            class: "elementor-default elementor-page elementor-kit-21",
          }}
        >
          <link rel="canonical" href={post.uri} />
          <link rel="icon" href={`/favicon.png`} sizes="32x32" />
          <link rel="icon" href={`/favicon.png`} sizes="192x192" />
          <link rel="apple-touch-icon" href={`/favicon.png`} />
          <link type="text/css" rel="stylesheet" href={styleTheme1} />
          <link type="text/css" rel="stylesheet" href={styleTheme2} />
          <link
            type="text/css"
            rel="stylesheet"
            href={styleElementorFrontEnd}
          />
          <link type="text/css" rel="stylesheet" href={styleElementorConfig} />
          <link type="text/css" rel="stylesheet" href={styleElementorIcons} />
          <link
            type="text/css"
            rel="stylesheet"
            href={styleElementorFontAwesome}
          />
          <link type="text/css" rel="stylesheet" href={styleElementorFASolid} />
          <link type="text/css" rel="stylesheet" href={styleElementorBrands} />
          <link type="text/css" rel="stylesheet" href={styleElementorPro} />
          <link
            type="text/css"
            rel="stylesheet"
            href={styleElementorGlobal}
            media="all"
          />
          <link
            type="text/css"
            rel="stylesheet"
            href={`${
              site?.siteMetadata?.cmsURL
            }/wp-content/uploads/elementor/css/post-${
              post.databaseId
            }.css?v=${new Date().getTime()}`}
          />
        </Helmet>

        <Header siteTitle={site?.siteMetadata?.siteTitle} />
        <div className="custom-post-single">
          <div className="back-button-container main-container">
            <Link to="/blog/">
              <img
                className="icon-back"
                src={IconBack}
                alt={site?.siteMetadata?.siteTitle}
              />{" "}
              Go back to overview
            </Link>
          </div>
          {post ? (
            <div className="custom-post-single-content main-container">
              <div className="custom-post-content-white">
                {/* {featuredImage?.data && (
                <div className="custom-post-banner">
                  <GatsbyImage
                    image={featuredImage.data}
                    alt={featuredImage.alt}
                  />
                </div>
              )} */}
                {postFeaturedImage && (
                  <div className="custom-post-banner">
                    <img src={postFeaturedImage} alt={post.title} />
                  </div>
                )}
                <div className="custom-post-content-white-inner">
                  {post?.categories?.nodes?.[0]?.name && (
                    <div className="custom-post-author">
                      {post?.categories?.nodes?.[0]?.name}
                    </div>
                  )}
                  <h1 className="custom-post-title-main">
                    {parse(post.title)}
                  </h1>
                  <div className="custom-post-content-block">
                    {post?.content &&
                      post?.content !== undefined &&
                      parse(post.content)}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p className="no-data">
              Sorry, data not found.
              <Link to="/" className="button-general">
                Back to homepage
              </Link>
            </p>
          )}
        </div>
        <Footer siteTitle={site?.siteMetadata?.siteTitle} />
      </div>
    </>
  );
};

export default SingleBlog;

export const pageQuery = graphql`
  query BlogById($id: String!, $previousPostId: String, $nextPostId: String) {
    post: wpPost(id: { eq: $id }) {
      id
      databaseId
      excerpt
      content
      title
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      categories {
        nodes {
          databaseId
          name
        }
      }
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          mediaItemUrl
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
    site {
      siteMetadata {
        cmsURL
        siteTitle
      }
    }
  }
`;
